import styled from 'styled-components'

export const Select = styled.select`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 40px;
  max-height: 100px;
  border: 1px solid #999;
  padding: 5px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
`
